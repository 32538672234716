.article {
  /* Табуляция */
  white-space: pre-wrap;
  tab-size: 4; /* Ширина табуляции */

  * {
    border-radius: 2px;
  }

  ol {
    padding-left: 40px !important;
    list-style: auto !important;
    margin: auto !important;

    li {
      margin: auto !important;
      list-style-type: decimal !important;
    }
  }

  ul {
    padding-left: 40px !important;
    list-style: auto !important;
    margin: auto !important;

    li {
      margin: auto !important;
      list-style-type: disc !important;
    }
  }


  blockquote {
    display: block !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 40px !important;
    margin-inline-end: 40px !important;

    border-left: 2px solid #bbbbbb;
    background-color: #f1f1f1;
    padding: 8px 16px;
  }


  hr {
    border: 0 !important;
    height: 1px !important;
    background: #ddd;
  }

  p {


    a {
      color: #0061ff !important;
      text-decoration: underline !important;

      &:hover {
        text-decoration: none !important;
      }
    }

    iframe {
      border-radius: 5px;
    }

    img {
      border-radius: 5px;
    }
  }

  figure {
    text-align: -webkit-center;
    border: 1px solid #00000000;
    border-radius: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse !important;


    thead {

      tr {
        th {
          border: 1px solid #20202020 !important;
          padding: 8px 12px !important;
          background-color: #20202010 !important;
          min-height: 1vh;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px 12px !important;
          border: 1px solid #20202020 !important;
          min-height: 1vh;
        }
      }
    }
  }

  &.edit {
    figure {
      border: 1px solid #0061ff00;
      transition: border ease .25s;

      &:hover {
        border: 1px solid #0061ff;
      }
    }
  }
}
