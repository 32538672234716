._block {
  background-color: #e1e1e1;
  padding: 10px 12px;
  border-radius: 7px;
  margin: 0 8px 8px 0;
  min-width: 144px;
  transition: background-color, box-shadow ease 0.25s;

  &_white {
    background-color: white;
  }

  &_hover {
    cursor: pointer;
    background-color: white;

    &:hover {
      box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
      z-index: 1;
    }
  }

  &__number {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
  }
}
