._block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;

  ._block__title {
    font-size: 21px;
    font-weight: 700;
    opacity: 0.5;
    margin-bottom: 16px;
  }

  ._block__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

._card {
  background-color: #e1e1e1;
  padding: 16px;
  border-radius: 11px;
  margin: 0 8px 8px 0;
  min-width: 144px;
  transition: background-color ease 0.25s, box-shadow ease 0.25s;

  &_white {
    background-color: white;
  }

  &_hover {
    cursor: pointer;
    &:hover {
      background-color: white;
      box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
      z-index: 1;
    }
  }

  &_active {
    cursor: default;
    background-color: white;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
    z-index: 1;
  }

  ._card__number {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ._card__title {
    font-size: 17px;
    font-weight: 500;
  }
}
