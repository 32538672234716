@charset "utf-8";
@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #f1f1f1;
  font-family: 'Source Sans Pro', sans-serif;
  color: #202020;
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

ul {
  list-style: none;
}

ol {
  list-style: none;
}

a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
}

input:focus, button:focus {
  outline: none;
}
