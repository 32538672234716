.Field {
  position: relative;
  padding: 0 2px;
  height: 59px;

  .Eye {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity ease 0.25s;
    position: absolute;
    top: 19px;
    right: 4px;

    &:hover {
      opacity: 1;
    }
  }

  .Label {
    transition: font-size ease 0.15s, left ease 0.15s, top ease 0.15s,
    opacity ease 0.15s, color ease 0.15s;
    position: absolute;
    left: 0;
    top: 20px;
    pointer-events: none;
    font-family: inherit;
    font-weight: 700;
    font-size: 19px;
    color: #202020;
    opacity: 0.75;

    &.active {
      opacity: 0.5;
      font-weight: 900;
      font-size: 15px;
      left: 1px;
      top: -10px;
    }
  }

  .Input {
    font-family: inherit;
    font-weight: inherit;
    font-size: 19px;
    caret-color: #202020;
    padding: 20px 0 13px;
    width: calc(100%);
    border-bottom: 2px solid #20202010;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    color: #202020 !important;
    background-color: inherit;
    transition: border ease 0.15s, border-color ease 0.09s;

    &:focus {
      border-bottom: 2px solid #20202090;
    }

    &:focus + .Label {
      opacity: 0.9;
      left: 1px;
      top: -10px;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 64px #ffffff inset;
      -webkit-text-fill-color: #202020;
      border-bottom: 2px solid #20202010;
      border-left: none;
      border-right: none;
      border-top: none;
      caret-color: #202020;
    }

    &[type="password"]:not(:placeholder-shown) {
      font: 900 26px Roboto !important;
      letter-spacing: 3px !important;
      padding: 19px 0 7px !important;
    }
  }
}
