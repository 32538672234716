.Swith {
  display: flex;
  width: 32px;
  height: 20px;
  border: 3px solid #333;
  border-radius: 99em;
  position: relative;
  transition: transform ease 0.25s, opacity ease 0.25s;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &:before {
    transition: transform ease 0.25s;
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #333;
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }

  &_gray {
    border: 3px solid #33333375;
  }

  &_active {
    background-color: #4caf50;
    border: 3px solid #4caf50;

    &:before {
      transform: translateX(12px);
      background-color: #fff;
    }

    &_red {
      background-color: inherit;
      border: 3px solid #ffab91;

      &:before {
        background-color: #bf360c;
      }
    }

    &_gray {
      background-color: inherit;
      border: 3px solid #33333375;

      &:before {
        background-color: #333;
      }
    }
  }
}
