@keyframes showModal {
  0% {
    opacity: 0;
    /*transform: scale(0.9);*/
    transform: translateY(10%);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    /*transform: scale(1);*/
    transform: translateY(0);
    pointer-events: auto;
  }
}

.show {
  animation: showModal 0.1s forwards;
}
