#editor {
  height: calc(100% - 42px);
  width: 100%;
  padding: 12px;
  position: relative;
  background-color: #ffffff;
  overflow-y: scroll;
  border: 1px solid #dddddd;
  transition: border ease 0.15s, box-shadow ease 0.15s;
}

#editor:focus {
  outline: none !important;
  border: 1px solid #0061ff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1) inset;
}

#editor[placeholder]:empty:before {
  content: attr(placeholder);
  color: #555;
}

#editor[placeholder]:empty:focus:before {
  content: "";
}

.toolbar a {
  display: inline-block;
  border: 1px solid #888;
  padding: 5px 8px;
  margin: 0 5px 10px 0;
  color: #000;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 1px 1px 2px #ddd;
  background: #fff;
  vertical-align: top;
  text-decoration: none;
}
.toolbar select {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  background: #fff;
  padding: 0;
  margin: 0 5px 10px 0;
  color: #000;
  box-shadow: 1px 1px 2px #ddd;
  border-radius: 3px;
  vertical-align: top;
  font-size: 12px;
}
.toolbar input {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  background: #fff;
  padding: 0;
  margin: 0 5px 10px 0;
  color: #000;
  box-shadow: 1px 1px 2px #ddd;
  border-radius: 3px;
  vertical-align: top;
  font-size: 12px;
}
.toolbar span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0;
  margin: 0 0 10px 0;
  color: #000;
  vertical-align: top;
  font-size: 12px;
}
