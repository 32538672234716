@import "./styles/Form.scss";
@import "./styles/List.scss";
@import "./styles/Table.scss";
@import "./styles/Button.scss";
@import "./styles/Swith.scss";
@import "./styles/Box.scss";
@import "./styles/Pagination.scss";

@import "./styles/Show.scss";
@import "./styles/Marker.scss";

.Page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;

  .Page__title {
    height: 42px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .Title__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 19px;
      font-weight: 700;
    }

    .Title__settings {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .Page__hr {
    width: 100%;
    border: 1px solid #e3e3e3;
    margin-bottom: 12px;
  }

  .Page__loader {
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .Page__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 12px;
  }
}

.App {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .Navigate {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    .track-vertical, .track-horizontal {
      opacity: 0 !important;
    }

    &:hover {
      .track-vertical, .track-horizontal {
        opacity: 0.7 !important;
      }
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    .track-vertical, .track-horizontal {
      opacity: 0 !important;
    }

    &:hover {
      .track-vertical, .track-horizontal {
        opacity: 0.75 !important;
      }
    }

    .Content__loader {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  &_vertical {
    flex-direction: row;

    .Navigate {
      height: 100% !important;
      box-shadow: none !important;
      //width: 256px;
      //width: 276px;
      width: 232px;
      //padding-right: 20px;
    }

    .Page {
      padding: 12px 20px 20px 20px;
      //padding: 12px 20px 20px 20px !important;
    }
  }

  &_horizontal {
    flex-direction: column;

    .Navigate {
      width: 100%;
      height: 62px;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);
    }

    .Page {
      padding: 12px 20px 20px 20px;
      //padding: 12px 20px 20px 20px !important;
    }
  }
}
