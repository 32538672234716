.Box {
  padding: 24px 24px 16px;
  display: inline-block;
  background-color: #e7e7e7;
  border-radius: 9px;
  margin-right: 24px;
  margin-top: 24px;
  width: 212px;

  .Box__title {
    margin-bottom: 12px;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 700;
  }

  .Box__number {
    font-size: 42px;
    font-weight: 900;
    text-align: right;
  }
}
