.Marker {
  width: 12px;
  height: 12px;
  background-color: #9e9e9e;
  border-radius: 50%;

  &_active {
    background-color: #4caf50;
  }

  &_left {
    margin-left: 12px;
  }

  &_inline {
    display: inline-block;
  }

  &_small {
    width: 8px;
    height: 8px;
    margin-bottom: 2px;
  }
}
