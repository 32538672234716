.Collected__count {
  display: flex;
  margin-left: 12px;
  flex-direction: column;
  font-size: 15px;
  border-radius: 24px;
  height: 28px;
  padding: 1px 8px 2px;
  color: #fff;
  font-weight: 900;
  align-items: center;
  justify-content: center;

  &_blue {
    // background-color: #0064ff;
    color: white;
    background-color: #1565c0;
    // color: #0064ff;
    //border: 2px solid rgba(0, 100, 255, 0.5);
    border: 2px solid #1565c0;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_green {
    // background-color: #0064ff;
    color: white;
    background-color: #2e7d32;
    // color: #0064ff;
    //border: 2px solid rgba(56, 142, 60, 0.5);
    border: 2px solid #2e7d32;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_yellow {
    // background-color: #0064ff;
    color: white;
    background-color: #cf9400;
    // color: #0064ff;
    border: 2px solid #cf9400;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_red {
    // background-color: #0064ff;
    color: white;
    background-color: #cf2f00;
    // color: #0064ff;
    border: 2px solid #cf2f00;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }
}

.Button__collected {
  display: flex;
  margin-left: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  //padding: 2px 2px 2px 2px;
  //cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  border-radius: 4px;

  &_blue {
    // background-color: #0064ff;
    color: white;
    background-color: #0064ff;
    // color: #0064ff;
    //border: 2px solid rgba(0, 100, 255, 0.5);
    border: 2px solid #0043ab;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_gray {
    // background-color: #0064ff;
    color: #202020;
    background-color: #bbbbbb;
    // color: #0064ff;
    border: 2px solid rgba(187, 187, 187, 0.5);

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_black {
    // background-color: #0064ff;
    color: white;
    background-color: #333;
    // color: #0064ff;
    border: 2px solid #333;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_green {
    // background-color: #0064ff;
    color: white;
    background-color: #388e3c;
    // color: #0064ff;
    //border: 2px solid rgba(56, 142, 60, 0.5);
    border: 2px solid #265f28;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }

  &_red {
    // background-color: #0064ff;
    color: white;
    background-color: #f53800;
    // color: #0064ff;
    border: 2px solid #f5380050;

    // &:hover {
    //   border: 2px solid rgba(0, 100, 255, 1);
    // }
  }
}

.Button__circle {
  display: inline-block;
  height: 32px;
  width: 32px;
  padding: 4px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 12px;
  cursor: pointer;
}

.Button__swith {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 6px;

  .Swith__text {
    margin-right: 8px;
    cursor: pointer;
    transition: opacity ease 0.25s;
    color: #202020;
    font-size: 14px;
    font-weight: 700;
  }
}

.Action__button {
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: opacity ease 0.1s, border ease 0.25s;
  font-family: inherit;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 700;
  background-color: inherit;

  &_all {
    width: 100%;
    margin-right: 0;
  }

  &_gray {
    color: #202020;
    border: 2px solid rgba(51, 51, 51, 0.5);

    &:hover {
      border: 2px solid rgba(51, 51, 51, 1);
    }
  }

  &_blue {
    // background-color: #0064ff;
    // color: white;
    // background-color: white;
    color: #0064ff;
    border: 2px solid rgba(0, 100, 255, 0.5);

    &:hover {
      border: 2px solid rgba(0, 100, 255, 1);
    }
  }

  &_red {
    // background-color: #ff0000;
    // color: white;
    // background-color: white;
    color: #ff0000;
    border: 2px solid rgba(255, 0, 0, 0.5);

    &:hover {
      border: 2px solid rgba(255, 0, 0, 1);
    }
  }

  &_right {
    margin-right: 12px;
  }

  &_mini {
    // padding: 4px 8px !important;
    padding: 0 4px 1px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 4px !important;
    transition: opacity ease 0.1s, border ease 0.25s !important;
  }

  &_maxi {
    padding: 3px 6px !important;
    font-size: 19px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
    transition: opacity ease 0.1s, border ease 0.25s !important;
  }

  // &:hover {
  //   opacity: 0.75;
  // }
}

.Action__link {
  color: #565656;
  // text-decoration: underline #565656;
  cursor: pointer;
  // transition: text-decoration ease 0.25s;
  font-size: 16px;
  display: inline-block;
  text-decoration: none;
  transition: opacity ease 0.1s;

  &_blue {
    color: #0064ff;
    // text-decoration: underline #0064ff;
  }

  &_red {
    color: #ff0000;
    // text-decoration: underline #ff0000;
  }

  &:hover {
    // text-decoration: underline white;
    opacity: 0.75;
  }

  &_right {
    margin-right: 12px;
  }

  &_left {
    margin-left: 12px;
  }
}

.Button__text {
  cursor: pointer;
  transition: opacity ease 0.25s;
  color: #202020;
  font-size: 17px;
  font-weight: 600;

  &:hover {
    opacity: 0.75;
  }

  &_left {
    margin-left: 24px;
  }

  &_right {
    margin-right: 24px;
  }
}
