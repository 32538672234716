.MenuHorizontal {
  padding: 8px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .Menu__list {
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .List__hr {
      height: 42px;
      width: 3px;
      background-color: #e1e1e1;
      border-radius: 2px;
    }

    .List__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 8px;
      font-size: 16px;
      cursor: pointer;

      .Link__text {
        font-size: 17px;
        opacity: 0.75;
        transition: opacity ease 0.25s, margin-left ease 0.25s;
        cursor: pointer;

        &_blue {
          &.active {
            color: #0061ff;
          }
        }

        &.active {
          cursor: default;
          margin-left: 0 !important;
          opacity: 1;
        }

        &.disabled {
          cursor: default !important;
          opacity: 0.5 !important;

          &:hover {
            opacity: 0.5 !important;
          }
        }

        .Text__title {
          font-size: 14px;
        }

        .Text__signature {
          opacity: 0.75;
          font-size: 14px;
        }
      }

      .Link__title {
        opacity: 0.75;
        transition: opacity ease 0.25s, margin-left ease 0.25s;
        font-size: 32px;
        text-decoration: none;
        font-weight: 600;

        &.active {
          cursor: default;
          margin-left: 0 !important;
          opacity: 1;
        }
      }

      .Link__icon {
        height: 42px;
        width: 42px;
        opacity: 0.33;
        transition: opacity ease 0.25s, margin-left ease 0.25s;

        &.active {
          cursor: default;
          margin-left: 0 !important;
          opacity: 1;
        }
      }

      &_header {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .Link__text {
          opacity: 1;
          text-align: right;
        }
      }

      &:hover {
        .Link__text,
        .Link__icon {
          opacity: 1;
        }
      }

      &:first-child {
        border-bottom: none;
        padding-left: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-right: 0;
      }
    }
  }

  .Menu__navigate {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 20px 20px;

    .Menu__list {
      margin-bottom: 12px;
      border-radius: 9px;
      display: block;

      &_box {
        border: 2px solid #d5d5d575;
        position: relative;

        .List__header {
          position: absolute;
          top: -22px;
          left: -4px;
          padding: 14px 12px;
          pointer-events: none;

          .Header__text {
            font-size: 13px;
            color: #9f9f9f;
            background-color: #f1f1f1;
            padding: 0 4px;
          }
        }
      }

      .List__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 6px 12px;

        &:first-child {
          padding-top: 12px;
        }

        &:last-child {
          padding-bottom: 12px;
        }

        .Link__text {
          font-size: 17px;
          opacity: 0.75;
          transition: opacity ease 0.25s, margin-left ease 0.25s;
          cursor: pointer;

          &.active {
            cursor: default;
            margin-left: 0 !important;
            opacity: 1;
          }

          .Text__title {
            font-size: 14px;
          }

          .Text__signature {
            font-size: 14px;
            opacity: 0.75;
          }
        }

        &_header {
          .Link__text {
            opacity: 1;
          }
        }

        &:hover {
          .Link__text {
            opacity: 1;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
