._block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;

  ._block__title {
    font-size: 21px;
    font-weight: 700;
    opacity: 0.5;
    margin-bottom: 16px;
  }

  ._block__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

._card {
  cursor: pointer;
  background-color: white;
  padding: 16px;
  border-radius: 11px;
  margin: 0 8px 8px 0;
  min-width: 144px;
  transition: box-shadow ease 0.25s;
  position: relative;

  &:hover {
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
    z-index: 1;
  }

  ._card__number {
    font-size: 37px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ._card__title {
    font-size: 17px;
    font-weight: 500;
  }

  ._card__amount {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
