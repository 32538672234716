.MenuVertical {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px 0 0 16px;

  .Menu__list {
    margin-bottom: 12px;
    border-radius: 9px;

    &_box {
      border: 2px solid #d5d5d575;
      position: relative;

      .List__header {
        position: absolute;
        top: -22px;
        left: -4px;
        padding: 14px 12px;
        pointer-events: none;

        .Header__text {
          font-size: 13px;
          color: #9f9f9f;
          background-color: #f1f1f1;
          padding: 0 4px;
        }
      }
    }

    .List__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 6px 12px;

      &:first-child {
        padding-top: 12px;
      }

      &:last-child {
        padding-bottom: 12px;
      }

      .Link__text {
        font-size: 17px;
        opacity: 0.75;
        transition: opacity ease 0.25s, margin-left ease 0.25s;
        cursor: pointer;

        &.active {
          cursor: default;
          margin-left: 0 !important;
          opacity: 1;
        }

        .Text__title {
          font-size: 14px;
        }

        .Text__signature {
          font-size: 14px;
          opacity: 0.75;
        }
      }

      &_header {
        .Link__text {
          opacity: 1;
        }
      }

      &:hover {
        .Link__text {
          opacity: 1;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
