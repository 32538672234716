ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
}

li.page-item {
  display: inline-block;
}

li.page-item.active a.page-link {
  color: #333333;
  opacity: 1;
}

a.page-link {
  text-align: center;
  margin: 0 6px;
  color: #000000;
  font-weight: 900;
  font-size: 18px;
  opacity: 0.5;
  transition: opacity ease 0.25s;
}

a.page-link:hover {
  opacity: 1;
}
