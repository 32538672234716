.Auth {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;

  .Form {
    margin: 56px 16px;
    padding: 32px;
    width: 338px;
    border-radius: 11px;
    background-color: white;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.11);

    .Form__logotype {
      margin-bottom: 0 !important;
    }

    .Form__title {
      color: #202020;
      font-family: inherit;
      font-size: 27px !important;
      font-weight: 400 !important;
      margin-bottom: 24px !important;

      .Text__signature {
        margin-top: 4px;
        font-size: 15px;
        padding-left: 1px;
        opacity: .75;
      }
    }

    .Form__field {
      margin-bottom: 24px;
    }

    .Form__actions {
      margin-top: 24px !important;
    }

    .Form__links {
      margin-top: 24px;
      text-align: center;
      font-size: 15px;
    }

    .Action__button {
      box-sizing: border-box;
      border-radius: 7px;
      border: none;
      cursor: pointer;
      transition: opacity ease 0.15s;
      font-family: inherit;
      padding: 13px 24px;
      font-size: 19px;
      font-weight: 700;

      &_login {
        color: white;
        background-color: #202020;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .Action__link {
      color: #565656;
      cursor: pointer;
      font-size: 15px;
      display: inline-block;
      text-decoration: none;
      transition: opacity ease 0.1s;


      &_blue {
        color: #0064ff;
      }

      &:hover {
        opacity: 0.75;
      }
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    /*transform: scale(0.9);*/
    transform: translateY(7%);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    /*transform: scale(1);*/
    transform: translateY(0);
    pointer-events: auto;
  }
}

.show {
  animation: showModal 0.25s forwards;
}
