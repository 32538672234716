._ {
  width: 796.661608px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: none;
  padding: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  ._hr {
    width: 1px;
    height: 24px;
    background-color: #dddddd;
    margin: 4px;
  }
}
