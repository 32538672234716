.track-horizontal {
    position: absolute;
    height: 8px !important;
    right: 2px;
    bottom: 2px;
    left: 2px;
    //border-radius: 2px;
    //background-color: #20202033;
    z-index: 999999;
        transition: opacity ease .25s;

    .track-thumb-horizontal {
        transition: opacity ease .25s;
    }

    &:hover {
        opacity: 1 !important;
    .track-thumb-horizontal {
        opacity: 1 !important;
        }
    }

    &:active {
        opacity: 1 !important;
    .track-thumb-horizontal {
        opacity: 1 !important;
        }
    }
}

.track-vertical {
    position: absolute;
    width: 6px !important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    //border-radius: 2px;
    //background-color: #20202033;
    z-index: 999999;
        transition: opacity ease .25s;


    .track-thumb-vertical {
        width: 3px !important;
        transition: width ease .15s, opacity ease .25s;
        float: right;
    }

    &:hover {
        opacity: 1 !important;
    .track-thumb-vertical {
        opacity: .75 !important;
            width: 8px !important;
        }
    }


    &:active {
        opacity: 1 !important;
.track-thumb-vertical {
        opacity: 1 !important;
            width: 8px !important;
        transition: width ease .15s;
        }
    }
}
