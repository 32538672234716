._ {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: 2px solid #dbdbdb;
  height: 34px;

  ._link {
    padding: 6px 1px;

    ._text {
      padding: 5px 6px 3px;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.5;
      transition: opacity ease 0.25s, margin-left ease 0.25s, background-color ease 0.25s, box-shadow ease 0.25s;
      cursor: pointer;
      border-radius: 3px;

      &.active {
        cursor: default;
        margin-left: 0 !important;
        opacity: 1;
        background-color: white;
        box-shadow: 0 16px 20px rgba(0, 0, 0, 0.11);
      }

      &.disabled {
        cursor: default !important;
        opacity: 0.5 !important;

        &:hover {
          opacity: 0.5 !important;
        }
      }
    }

    &:hover {
      ._text {
        opacity: 1;
      }
    }

    &:first-child {
      padding-left: 2px;
    }

    &:last-child {
      padding-right: 2px;
    }
  }
}
