._block {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    transition: opacity 200ms ease-in;
    margin: 0;
    padding: 0;
    opacity: 1;
    pointer-events: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    flex-wrap: nowrap;
    align-content: center;

    ._mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        animation: opacity 0.33s;
        z-index: 1;
    }

    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    ._close {
        padding: 32px;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 3;

        &__button {
            background-color: rgba(0, 0, 0, 0.56);
            height: 48px;
            width: 48px;
            border-radius: 50%;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            transition: background-color ease 0.25s;

            &:hover {
                background-color: rgba(0, 0, 0, 1);
            }
        }
    }

    ._image {
        border-radius: 7px;
        position: relative;
        margin: 32px;
        animation: showModal 0.1s;
        z-index: 2;
    }

    ._load {
        animation: showModal 0.1s;
        z-index: 2;
    }

    @keyframes showModal {
        0% {
            opacity: 0;
            transform: scale(0.9);
            pointer-events: none;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            pointer-events: auto;
        }
    }
}
