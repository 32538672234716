.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 0;

  .Thead {
    z-index: 1;

    .Table__tr {
      z-index: 1;
      border: 2px solid #202020 !important;

      .Table__th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: #f1f1f1;
        z-index: 2;

        border: 1px solid #202020 !important;

        &_sticky {
          position: sticky;
          left: 0;
          z-index: 3;
        }
      }
    }
  }

  .Table__tr {
    border: 2px solid #c5c5c5;
    // border: 2px solid #dbdbdb;
    transition: background-color ease 0.1s, border ease 0.1s, opacity ease 0.1s;

    &_hover {
      &:hover {
        background-color: #20202033 !important;
      }
    }

    &_focus {
      background-color: #dbdbdb;
      border-bottom: 2px solid #202020 !important;
    }

    &_focus_light {
      background-color: #e5e5e5;
    }

    .Table__th {
      padding: 12px 12px 12px 16px;

      &_border {
        border: 2px solid #c5c5c5;
      }

      .Th__title {
        font-size: 12px;
        font-weight: 700;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        &_flex {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .Table__td {
      font-size: 17px;
      font-weight: 400;
      padding: 12px;
      transition: ease 0.25s;

      &_sticky {
        position: sticky;
        left: 0;
        z-index: 1;
      }

      &_border {
        border: 2px solid #c5c5c5;
      }

      &_tiger {
        background: repeating-linear-gradient(
          45deg,
          #ffffff00,
          #ffffff00 17px,
          rgb(0 0 0 / 07%) 17px,
          rgb(0 0 0 / 07%) 34px
        );
      }

      &_child {
        &:first-child {
          padding: 0 16px;
          width: 36px;
          //border-top-left-radius: 7px;
          //border-bottom-left-radius: 7px;
        }

        &:nth-child(2) {
          width: 30%;
          padding: 0 12px 0 0;
        }

        &:nth-child(3) {
          width: 30%;
          padding: 0 12px 0 0;
        }

        &:last-child {
          padding: 0 12px;
          width: 16px;
          //border-bottom-right-radius: 7px;
          //border-top-right-radius: 7px;
        }
      }

      .Td__title {
        font-size: 18px;
        height: 100%;
        width: 100%;

        &_flex {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .Td__text {
        font-size: 13px;
        font-weight: 700;
        height: 100%;
        width: 100%;

        &_center {
          text-align: center;
        }
      }
    }

    &_padding {
      .Table__th {
        padding: 2px 2px;

        &_hr {
          padding: 0;
        }

        .Th__title {
          padding: 2px 4px;
        }
      }

      .Table__td {
        padding: 2px 2px;

        .Td__text {
          padding: 2px 4px;
        }
      }
    }

    &_border {
      border: 2px solid #808080;

      &:hover {
        border-bottom: 2px solid #202020;
        z-index: 1;
      }

      .Table__th {
        border: 1px solid #808080;

        &_hr {
          border: none;
        }
      }

      .Table__td {
        border: 1px solid #808080;
      }
    }
  }
}
