.NomenclaturesMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 24px 24px 24px 24px;

  .Menu__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .Header__text {
      font-size: 19px;
      font-weight: 700;
      cursor: default;
    }
  }

  .Menu__search {
    margin-bottom: 16px;
  }

  .Menu__list {
    padding: 16px 0 24px;



    .List__title {
      font-size: 16px;
      font-weight: 700;
      margin-top: 16px;
      margin-bottom: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    .List__group {
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .List__menu {
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0 0 0 10px;
      border-left: 2px solid #20202015;
    }

    .List__link {
      margin-bottom: 8px;

      .Link__text {
        cursor: pointer;
        font-size: 16px;
        opacity: 1;
        transition: opacity ease 0.25s, margin-left ease 0.25s;
        color: #202020;

        &:hover {
          opacity: 0.75;
          //margin-left: 4px;
        }

        &.active {
          cursor: default;
          //margin-left: 0 !important;
          opacity: 1;
          color: #0061ff;
          //color: #202020;

        }
      }
    }
  }
}
