.Keyboard {
    left: 0;
    right: 0;
    bottom: 0;
    // padding-bottom: 32px;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 9999999;
}

.preview {
    max-width: 750px;
    margin: 0 auto;
    // margin-top: 16px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    pointer-events: auto;
    border-radius: 5px;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.33);
    margin-bottom: 32px;
}

.simple-keyboard-preview {
    transition: background .3s ease-in-out;
    background: #2e2e2e;
    border: 20px solid rgba(0,0,0,.1);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.input {
    color: hsla(0,0%,100%,.9);
    background: 0 0;
    border: none;
    outline: 0;
    font-family: monospace;
    width: 100%;
    font-size: 18px;
}

.simple-keyboard-preview .input {
    // padding: 20px;
}
