.Tickets {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    overflow: hidden;
    //padding-left: 12px;

  .Menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
    height: 62px;
    /*box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.15);*/
    z-index: 1;
  }

  .Block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    .Navigate {
        z-index: 0;
    }

    .Content {
        z-index: 0;
    }
  }
}

.TicketsPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;

  .Page__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;

    .Title__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 19px;
      font-weight: 700;
    }

    .Title__settings {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .Page__hr {
    width: 100%;
    border: 1px solid #e3e3e3;
    margin-bottom: 12px;
  }

  .Page__loader {
    margin-top: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .Page__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 12px;
  }
}
