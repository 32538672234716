._block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;

  ._block__title {
    font-size: 21px;
    font-weight: 700;
    opacity: 0.5;
    margin-bottom: 16px;
  }

  ._block__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &_bottom {
    margin-bottom: 24px;
  }
}
