.List {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;

  .List__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    transition: background-color ease 0.25s, box-shadow ease 0.25s;
    background-color: #e1e1e1;
    border-radius: 7px;
    cursor: pointer;
    margin-bottom: 8px;
    padding: 8px 12px;
    z-index: 1;

    &:hover {
      background-color: #ffffff;
      z-index: 2;
    }

    &.active {
      background-color: #ffffff;
      box-shadow: 0 16px 20px rgba(0, 0, 0, 0.07);
      z-index: 2;

      &:hover {
        background-color: #ffffff;
        z-index: 3;
      }
    }

    .Item__title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .Item__settings {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;

      .Settings__size {
        font-size: 24px;
        font-weight: 900;
      }
    }
  }

  .List__content {
    padding: 0 8px 16px;
    z-index: 0;

    &:hover {
      z-index: 4;
    }

    &.active {
      z-index: 2;
    }

    .Content__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 36px;

      .Header__title {
        display: flex;
        align-items: center;
      }

      .Header__settings {
        display: flex;
        align-items: center;
      }
    }

    .Content__footer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;

      .Footer__title {
        display: flex;
        align-items: center;
      }

      .Footer__settings {
        display: flex;
        align-items: center;
      }
    }
  }

  .Element__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
  }

  .Element__size {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .Size__title {
      font-size: 16px;
      font-weight: 400;
    }

    .Size__count {
      font-size: 24px;
      font-weight: 900;

      &_left {
        margin-left: 12px;
      }
    }
  }

  .Element__datetime {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}
