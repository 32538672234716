.Form {
  .Form__title {
    margin-bottom: 16px;
    font-size: 19px;
    font-weight: 700;

    .Text__signature {
      margin-top: 4px;
      font-size: 13px;
      padding-left: 1px;
      opacity: 0.75;
    }
  }

  .Form__field {
    margin-bottom: 10px;

    &_last {
      margin-bottom: 0;
    }

    .Field__label {
      font-size: 13px;
      opacity: .75;
      margin-bottom: 2px;
      padding: 0 4px;
    }

    .Field__text {
      font-size: 17px;
      padding: 0 4px;
    }
  }

  .Form__error {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #ff5722;
  }

  .Form__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &:last-child {
      padding: 0 2px;
      margin-top: 16px;
    }

    &_center {
      justify-content: center;
    }
  }
}
